import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Checkout from '../components/payment/checkout/Checkout';
import { GetCheckoutStart, GetVoucherValidationToken } from '../services/PaymentService';
import { ResetBookingCookie } from '../services/AbandonedBookingService';
import paymentHelper from '../helpers/PaymentHelper';
import Skeleton from 'react-loading-skeleton';
import ErrorMessage from '../components/shared/errorMessage/ErrorMessage';
import CheckoutMobileHeading from '../components/payment/mobileHeading/CheckoutMobileHeading';
import Navigation from '../components/shared/navigation/Navigation';
import axios from 'axios';
import FullScreenLoading from '../components/shared/loading/FullScreenLoading';
import useBus, { dispatch } from 'use-bus'
import { RemoveVoucherResource } from '../services/VoucherService';
import AnalyticsHelper from '../helpers/AnalyticsHelper';
import CurrencySymbolHelper from '../helpers/CurrencySymbolHelper';
import { useTranslation } from 'react-i18next';
import { appInsights } from '../services/AppInsights';
import AppInsightsHelper from '../helpers/AppInsightsHelper';
import cookieHelper from '../helpers/CookieHelper';

const Payment = (props) => {
	const [isLoaded, setIsLoaded] = useState(false);
	const [isSdkLoaded, setIsSdkLoaded] = useState(false);
	const [checkoutResponse, setCheckoutResponse] = useState([]);
	const [selectedPaymentOption, setSelectedPaymentOption] = useState();
	const [errorMessage, setErrorMessage] = useState("");

	const [successMessage, setSuccessMessage] = useState("");
	const [checkoutTimeElapsed, setCheckoutTimeElapsed] = useState(0);
	const [isPaymentBeingProcessed, setIsPaymentBeingProcessed] = useState(false);
	const { t } = useTranslation();
	let history = useHistory();
	const isPaymentCompleted = sessionStorage.getItem("PaymentCompleted") === "true";
	let unsupportedAcquirers = localStorage.getItem("UnsupportedAcquirers");

	function paymentEvent_Status(event) {
		setIsPaymentBeingProcessed(true);
		getStatusCaller(event.detail.StatusURI);
	}

	useEffect(() => {
		if (isPaymentBeingProcessed)
			AnalyticsHelper.TrackPaymentAttempt();

	}, [isPaymentBeingProcessed])

	document.title = props.tabTitle;

	useBus('onPaymentCurrencyChanged', async () => {
		await RemoveVoucherResource();
		history.go(0);
		dispatch("onCurrencyChanged");
	}, []);

	useEffect(() => {
		if (!isPaymentCompleted) {
			startCheckout();
		}
	}, []);

	useEffect(() => {
		if (!isPaymentCompleted) {
			localStorage.setItem("storedCheckoutResponse", JSON.stringify(checkoutResponse));
		}
	}, [checkoutResponse]);

	useEffect(() => {
		AnalyticsHelper.TrackPageLoad("payment_load", "Booking Payments");
	}, [])

	useEffect(() => {
		if (!isPaymentCompleted) {
			localStorage.setItem("storedSelectedPaymentOption", JSON.stringify(selectedPaymentOption));
		}
	}, [selectedPaymentOption]);

	useBus('onNavigation', () => {
		removeEventListeners();
	});

	function setError(message) {
		setSuccessMessage("");
		setErrorMessage(message);
		window.scrollTo(0, 0);
	}

	function setSuccess(message) {
		setErrorMessage("");
		setSuccessMessage(message);
		window.scrollTo(0, 0);
	}

	function createVoucherValidationTokenInterval(validationToken) {
		setVoucherValidationToken(validationToken)
		clearInterval(window.voucherValidationTokenInterval)
		window.voucherValidationTokenInterval = setInterval(() => {
			setVoucherValidationToken(validationToken)
		}, 3000000)
	}

	function createVoucherValidationTokenIntervalFromStoredToken() {
		var storedToken = sessionStorage.getItem("VoucherValidationToken")
		if(storedToken) {
			createVoucherValidationTokenInterval(storedToken)
		}
	}

	function setVoucherValidationToken(validationToken){
		GetVoucherValidationToken(validationToken)
		.then(response => {
			if(response && response.data) {
				var setTokenInPaymentInterval = setInterval(() => {
					if(window.Payment) {
						window.Payment.setVoucherValidationToken(response.data.token)
						clearInterval(setTokenInPaymentInterval)
					}
				}, 200)
			}

		})
		.catch()
		.finally()
	}



	function startCheckout() {
		setIsLoaded(false);
		GetCheckoutStart()
			.then(response => {
				if (response && response.data) {
					let checkoutResponseData = response.data;
					if (checkoutResponseData && checkoutResponseData.invoice) {
						localStorage.setItem("TotalCost", checkoutResponseData.invoice.totalCosts[0].amount);
						var villaPrice = checkoutResponseData.invoice.costs.find((x) => { return x.type === "Accommodation.Booking" && x.key === "Villa price" });

						if (villaPrice) {
							localStorage.setItem("VillaPrice", villaPrice.totalAmount);
						}
					}
					setCheckoutResponse(checkoutResponseData);
					window.PaymentStatusMaximumPollTimeMs = checkoutResponseData.paymentStatusMaximumPollTimeMs;
					const cheapestOption = paymentHelper.sortPaymentOptionsByCost(checkoutResponseData.paymentOptions)[0];
					let cachedSelectedPaymentOption = JSON.parse(sessionStorage.getItem("SelectedPaymentOption"));
					setSelectedPaymentOption(!cachedSelectedPaymentOption ? cheapestOption : cachedSelectedPaymentOption);

					paymentHelper.addProcessorSdkToDOM(checkoutResponseData.paymentProcessorSdk);
					addPaymentEvent_Loaded(checkoutResponseData);
					window.addEventListener("vp-payment-renew-token-event", handlePaymentTokenRenewal);
					addPaymentEvent_ValidationError();
					window.addEventListener("vp-payment-status-event", paymentEvent_Status);
					window.addEventListener("vp-payment-server-error-event", handlePaymentServerError);
					window.addEventListener("vp-3dsecure-response-timeout-event", handleThreeDSecureResponseTimeout);
					addPaymentEvent_ThreeDSecureError();

					window.addEventListener("vp-payment-form-submitted", addPaymentEvent_FormSubmitted);
					window.addEventListener("vp-ts-and-cs-checked", addPaymentEvent_TsAndCsChecked);
					window.addEventListener("vp-payment-method-changed", addPaymentEvent_MethodChanged);

					var voucherMethod = response.data.paymentMethods.find(p => p.key === "PaymentVoucher");
					if(voucherMethod) {
						if(voucherMethod.validationToken) {
							sessionStorage.setItem("VoucherValidationToken", voucherMethod.validationToken)
							createVoucherValidationTokenInterval(voucherMethod.validationToken);
						}
						else {
							createVoucherValidationTokenIntervalFromStoredToken();
						}
					}
					else {
						createVoucherValidationTokenIntervalFromStoredToken();
					}

					if (checkoutResponseData.errors.length > 0) {
						var errorMessage = checkoutResponseData.errors.find(i => i.errorCode.includes("VoucherValidationError"));
						if (errorMessage) {
							localStorage.removeItem("AppliedVoucher");
							setErrorMessage(t(errorMessage.errorCode));
						}
					}
					else {
						var appliedVoucher = localStorage.getItem("AppliedVoucher");
						if (appliedVoucher) {
							var appliedVoucherJson = JSON.parse(appliedVoucher);
							appliedVoucherJson.Amount = checkoutResponseData.paymentOptions[0].voucherApplied.discount.amount;
							appliedVoucherJson.Description = checkoutResponseData.paymentOptions[0].voucherApplied.description;
							localStorage.setItem("AppliedVoucher", JSON.stringify(appliedVoucherJson));
						}
					}
				}
			})
			.catch(error => {
				const message = error.response.data.message;
				const errorCode = error.response.data.errorCode;
				if (errorCode === "RenewalError")
					dispatch("AccommodationRenewalFailed");
				setError(message);
				console.error(error);
			})
			.finally(() => {
				setIsLoaded(true);
				setIsPaymentBeingProcessed(false);
			});
	}

	function addPaymentEvent_Loaded(checkoutResponseData) {
		window.addEventListener("vp-payment-sdk-loaded-event", (event) => {
			setIsSdkLoaded(true);

			if (!window.Payment || !window.Payment.setPaymentOption) {
				AppInsightsHelper.logInvalidPaymentSDK();
			}
			let storedSelectedPaymentOption = JSON.parse(localStorage.getItem("storedSelectedPaymentOption"));

			appInsights.trackEvent({
				name: 'addPaymentEvent_Loaded - storedSelectedPaymentOption',
				properties: {
					bookingRef: localStorage.getItem("BookingRef"),
					paymentOption: JSON.stringify(storedSelectedPaymentOption)
				}
			});


			if (storedSelectedPaymentOption == null || storedSelectedPaymentOption == undefined) {
				storedSelectedPaymentOption = checkoutResponseData.paymentOptions[0];
				localStorage.setItem("storedSelectedPaymentOption", JSON.stringify(storedSelectedPaymentOption));
				sessionStorage.setItem("SelectedPaymentOption", JSON.stringify(storedSelectedPaymentOption));
			}

			const method = checkoutResponseData.paymentMethods.filter(x => x.key === storedSelectedPaymentOption.applicablePaymentMethods[0])[0];
			window.Payment.setPaymentOption(storedSelectedPaymentOption, method, checkoutResponseData.invoice.hash);

			const environment = localStorage.getItem("Environment");
			const termsAndConditionsLink = environment.toLowerCase() === "production" ? "https://book.villaplus.com/booking-conditions" : "https://book.villaplustest.com/booking-conditions";
			window.Payment.setTermsAndCondition(t("TERMS_AND_CONDITIONS_PART_ONE"), t("TERMS_AND_CONDITIONS_PART_TWO"), termsAndConditionsLink);

		});
	}

	function getStatusCaller(statusURI) {
		fetch(
			statusURI, {
			credentials: 'include',
			headers: { Pragma: 'no-cache' }
		})
			.then(res => res.json())
			.then(
				async (response) => {
					appInsights.trackEvent({
						name: 'vp-payment-status-event',
						properties: {
							response: response,
							bookingRef: localStorage.getItem("BookingRef")
						}
					});
					if (response.status == "InProgress" && checkoutTimeElapsed < window.PaymentStatusMaximumPollTimeMs) {
						setTimeout(() => {
							setCheckoutTimeElapsed(checkoutTimeElapsed + response.fetchAfterMs);
							getStatusCaller(statusURI);
						}, response.fetchAfterMs);
					} else if (response.status == "Paid" || response.status == "Success") {
						// Successful payment
						AnalyticsHelper.TrackSuccessfulPayment();
						sessionStorage.setItem("confirmationPageLink", response.confirmationPageLink);
						ProcessPostBookingActions();
						ResetBookingCookie();
						setIsPaymentBeingProcessed(false);
						sessionStorage.setItem("PaymentCompleted", true);
						dispatch("paymentSuccessful");
						//Clear interval and data
					} else {
						// Any error
						let message;
						let bookingRef = localStorage.getItem("BookingRef");
						//window.Payment.resetButtons();
						if (typeof (window.PaymentSDKInitializationData) != "undefined" && typeof (window.PaymentSDKInitializationData.AcquirerName) != "undefined") {
							if (unsupportedAcquirers == null) {
								unsupportedAcquirers = JSON.stringify([]);
							}
							if (!unsupportedAcquirers.includes(window.PaymentSDKInitializationData.AcquirerName)) {
								appInsights.trackEvent({
									name: 'add-unsupported-acquirer',
									properties: {
										unsupportedAcquirers: JSON.stringify(unsupportedAcquirers),
										pushedUnsupportedAcquirer: window.PaymentSDKInitializationData.AcquirerName,
										bookingRef: localStorage.getItem("BookingRef")
									}
								});

								unsupportedAcquirers = JSON.parse(unsupportedAcquirers);
								unsupportedAcquirers.push(window.PaymentSDKInitializationData.AcquirerName);
								localStorage.setItem("UnsupportedAcquirers", JSON.stringify(unsupportedAcquirers));
							}
						}

						appInsights.trackEvent({
							name: 'unsupported-acquirer-added-to-collection',
							properties: {
								supportIdentifiers: response.errors[0].supportIdentifiers,
								bookingRef: localStorage.getItem("BookingRef")
							}
						});

						//TODO: Need to think how to handle this so a proper reset can occur at all times
						if (!window.Payment.unload) {
							appInsights.trackEvent({
								name: 'undefined-window.Payment.unload',
								properties: {
									supportIdentifiers: response.errors[0].supportIdentifiers,
									bookingRef: localStorage.getItem("BookingRef")
								}
							});
						}

						window.Payment.unload(function () {
							removeEventListeners();
							startCheckout();
							appInsights.trackEvent({
								name: 'invoked-startCheckout-on-unload',
								properties: {
									supportIdentifiers: response.errors[0].supportIdentifiers,
									bookingRef: localStorage.getItem("BookingRef")
								}
							});

							if (response.errors[0].errorCode === "InvoiceMismatch") {
								message = t("GENERIC_CHECKOUT_ERROR") + " " + bookingRef + " - " + response.errors[0].supportIdentifiers;
								setError(message);
							}
							else if (response.errors[0].errorCode === "AuthorizationFailed") {
								message = t("AUTHORIZATIONFAILED") + " " + bookingRef + " - " + response.errors[0].supportIdentifiers;
								setError(message);
							}
							else if (response.errors[0].errorCode === "AuthorizationFailedTryDifferentCard") {
								message = t("AUTHORIZATION_FAILED_TRY_DIFFERENT_CARD") + " " + bookingRef + " - " + response.errors[0].supportIdentifiers;
								setError(message);
                            }
							else {
								message = response.errors[0].friendlyErrorMessage + " " + bookingRef + " - " + response.errors[0].supportIdentifiers;
								setError(message);

							}

							appInsights.trackEvent({
								name: 'vp-payment-status-event-error',
								properties: {
									response: response.errors[0].friendlyErrorMessage,
									bookingRef: localStorage.getItem("BookingRef")
								}
							});

							setIsPaymentBeingProcessed(false);
						});
					}
				});
	}

	async function handlePaymentServerError(event) {
		let bookingRef = localStorage.getItem("BookingRef");

		appInsights.trackEvent({
			name: 'vp-payment-server-error-event',
			properties: {
				response: event.detail.userFriendlyMessage,
				bookingRef: bookingRef,
				supportIdentifiers: event.detail.supportIdentifiers
			}
		});

		let message = event.detail.userFriendlyMessage;


		if (event.detail.errorCode === "vp-server-error")
			message = t("VP_SERVER_ERROR");
		else if (event.detail.errorCode === "vp-card-declined-error")
			message = t("VP_CARD_DECLINED_ERROR");
		else if (event.detail.errorCode === "vp-unsupported-card-type-error")
			message = t("VP_UNSUPPORTED_CARD_TYPE_ERROR");

		message = message + " " + bookingRef + " - " + event.detail.supportIdentifiers;
		if (typeof (window.PaymentSDKInitializationData) != "undefined" && typeof (window.PaymentSDKInitializationData.AcquirerName) != "undefined") {
			if (unsupportedAcquirers == null) {
				unsupportedAcquirers = JSON.stringify([]);
            }
			if (!unsupportedAcquirers.includes(window.PaymentSDKInitializationData.AcquirerName)) {
				appInsights.trackEvent({
					name: 'add-unsupported-acquirer',
					properties: {
						unsupportedAcquirers: JSON.stringify(unsupportedAcquirers),
						pushedUnsupportedAcquirer: window.PaymentSDKInitializationData.AcquirerName,
						bookingRef: localStorage.getItem("BookingRef")
					}
				});

				unsupportedAcquirers = JSON.parse(unsupportedAcquirers);
				unsupportedAcquirers.push(window.PaymentSDKInitializationData.AcquirerName);
				localStorage.setItem("UnsupportedAcquirers", JSON.stringify(unsupportedAcquirers));
			}
		}
		window.Payment.unload(function () {
			removeEventListeners();
			startCheckout();
		});

		setError(message);
	}

	async function handleThreeDSecureResponseTimeout(event) {
		let bookingRef = localStorage.getItem("BookingRef");

		appInsights.trackEvent({
			name: 'vp-3dsecure-response-timeout-event',
			properties: {
				response: event.detail.userFriendlyMessage,
				bookingRef: bookingRef,
				supportIdentifiers: event.detail.supportIdentifiers
			}
		});

		let message = event.detail.userFriendlyMessage;

		message = message + " " + bookingRef + " - " + event.detail.supportIdentifiers;

		if (typeof (window.PaymentSDKInitializationData) != "undefined" && typeof (window.PaymentSDKInitializationData.AcquirerName) != "undefined") {
			if (unsupportedAcquirers == null) {
				unsupportedAcquirers = JSON.stringify([]);
			}
			if (!unsupportedAcquirers.includes(window.PaymentSDKInitializationData.AcquirerName)) {
				appInsights.trackEvent({
					name: 'add-unsupported-acquirer',
					properties: {
						unsupportedAcquirers: JSON.stringify(unsupportedAcquirers),
						pushedUnsupportedAcquirer: window.PaymentSDKInitializationData.AcquirerName,
						bookingRef: localStorage.getItem("BookingRef")
					}
				});

				unsupportedAcquirers = JSON.parse(unsupportedAcquirers);
				unsupportedAcquirers.push(window.PaymentSDKInitializationData.AcquirerName);
				localStorage.setItem("UnsupportedAcquirers", JSON.stringify(unsupportedAcquirers));
			}
		}
		window.Payment.unload(function () {
			removeEventListeners();
			startCheckout();
		});

		setError(message);
	}

	function handlePaymentTokenRenewal(event) {
		let bookingRef = localStorage.getItem("BookingRef");
		let leadLastName = localStorage.getItem("LeadLastName");

		axios.get("/api/payment/getpaymenttoken?bookingRef=" + bookingRef + "&leadLastName=" + leadLastName,
			{
				withCredentials: true, headers: { Pragma: "no-cache" }
			})
			.then((response) => {
				var token = response.data;
				window.Payment.setECommerceToken(token);
			}, (error) => {
				const message = error.response.data.message;
				setError(message);
				console.error(error.response);
			});
    }

	async function ProcessPostBookingActions() {
		let vscChecked = sessionStorage.getItem("vscChecked") === "true";

		const bookingReference = localStorage.getItem("BookingRef");
		const leadName = localStorage.getItem("LeadFullName");
		const lastName = localStorage.getItem("LeadLastName");
		const leadEmailId = localStorage.getItem("LeadEmail");
		const documentLink = localStorage.getItem("DocumentLink");
		const historyLink = localStorage.getItem("HistoryLink");
		let selectedLanguage = cookieHelper.getCookie("SelectedLanguageOption");

		if (selectedLanguage == "en-US")
			selectedLanguage = "en";

		let totalCost = localStorage.getItem("TotalCost")
		let currencySymbol = CurrencySymbolHelper.GetCurrencySymbol(localStorage.getItem("CurrencyCode"));
		let eCommerceResource = localStorage.getItem("ECommerceBookingLink");

		const inventoryResource = localStorage.getItem("InventoryResource");

		await axios.post("/api/payment/ProcessPostBookingActions",
			{
				bookingRef: bookingReference,
				leadEmail: leadEmailId,
				leadFullName: leadName,
				leadLastName: lastName,
				documentLink: documentLink,
				historyLink: historyLink,
				language: selectedLanguage,
				vscChecked: vscChecked,
				currency: currencySymbol,
				eCommerceResource: eCommerceResource,
				bookingPrice: totalCost,
				inventoryResource: inventoryResource
			});
	}

	function removeEventListeners() {
		window.removeEventListener("vp-payment-status-event", paymentEvent_Status);
		window.removeEventListener("vp-payment-renew-token-event", handlePaymentTokenRenewal);
		window.removeEventListener("vp-3dsecure-response-timeout-event", handleThreeDSecureResponseTimeout);
		window.removeEventListener("vp-payment-server-error-event", handlePaymentServerError);
		window.removeEventListener("vp-payment-form-submitted", addPaymentEvent_FormSubmitted);
		window.removeEventListener("vp-ts-and-cs-checked", addPaymentEvent_TsAndCsChecked);
		window.removeEventListener("vp-payment-method-changed", addPaymentEvent_MethodChanged);
    }

	function addPaymentEvent_ThreeDSecureError() {
		let bookingRef = localStorage.getItem("BookingRef")
		window.addEventListener('vp-payment-threeDSecure-error-event',
			async event => {
				appInsights.trackEvent({
					name: 'vp-payment-threeDSecure-error-event',
					properties: {
						response: event.detail.userFriendlyMessage,
						bookingRef: bookingRef,
						supportIdentifiers: event.detail.supportIdentifiers
					}
				});

				let message = event.detail.userFriendlyMessage;

				if (event.detail.errorCode === "vp-threeDSecure-error")
					message = t("VP_THREEDSECURE_ERROR");
				else if (event.detail.errorCode === "vp-card-declined-error")
					message = t("VP_THREEDSECURE_CARD_DECLINED_ERROR");
				else if (event.detail.errorCode === "vp-threeDSecure-authentication-cancelled-error")
					message = t("VP_THREEDSECURE_AUTHENTICATION_CANCELLED_ERROR");

				message = message + " " + "<br />" + t("VILLAPLUS_REFERENCE_TEXT") + bookingRef + " - " + event.detail.supportIdentifiers;

				setError(message);
			});
	}

	function addPaymentEvent_ValidationError() {
		let bookingRef = localStorage.getItem("BookingRef")

		window.addEventListener(
			"vp-payment-validation-error-event",
			async event => {

				let message = getErrorMessageForCode(event.detail.errorCode);

				appInsights.trackEvent({
					name: 'vp-payment-validation-error-event',
					properties: {
						response: "error code: " + event.detail.errorCode + "; shown message: " + message + "; original message: " + event.detail.userFriendlyMessage,
						supportIdentifiers: event.detail.supportIdentifiers,
						bookingRef: bookingRef
					}
				});

				message = message + " " + bookingRef + " - " + event.detail.supportIdentifiers;
				setError(message);

				window.Payment.resetButtons();
			});
	}

	function getErrorMessageForCode(errorCode) {
		switch (errorCode.toLowerCase()) {
			case "vp-input-error-all":
				return t("INPUT_ERROR_ALL");
			case "vp-input-error-card number-security code-expiry date":
				return t("INPUT_ERROR_CARD_SECURITY_EXPIRY");
			case "vp-input-error-card number":
				return t("INPUT_ERROR_CARD_NUMBER");
			case "vp-input-error-security code":
				return t("INPUT_ERROR_SECURITY_CODE");
			case "vp-input-error-expiry date":
				return t("INPUT_ERROR_EXPIRY_DATE");
			case "vp-input-error-card number-security code":
				return t("INPUT_ERROR_CARD_SECURITY");
			case "vp-input-error-card number-expiry date":
				return t("INPUT_ERROR_CARD_EXPIRY");
			case "vp-input-error-security code-expiry date":
				return t("INPUT_ERROR_SECURITY_EXPIRY");
			case "vp-payment-validation-error-event":
				return t("VP_INPUT_ERROR_GENERIC");
			case "vp-unsupported-card-type-error":
				return t("VP_UNSUPPORTED_CARD_TYPE_ERROR");
			default:
				return t("INPUT_ERROR_GENERIC");
		}
	}

	function paymentOptionClickHandler(paymentOption) {
		if (!window.Payment || !window.Payment.setPaymentOption) {
			AppInsightsHelper.logInvalidPaymentSDK();
		}

		let isCheckoutNotNull = checkoutResponse != null && checkoutResponse != undefined ? true : false;

		appInsights.trackEvent({
			name: 'paymentOptionClickHandler clicked',
			properties: {
				paymentOption: JSON.stringify(paymentOption),
				paymentMethods: JSON.stringify(checkoutResponse.paymentMethods),
				checkoutResponseNotNull: isCheckoutNotNull,
				bookingRef: localStorage.getItem("BookingRef")
			}
		});

		setSelectedPaymentOption(paymentOption);
		const method = paymentOption.applicablePaymentMethods[0];
		const paymentMethod = checkoutResponse.paymentMethods.find((x => { return method === x.key }));
		let hash = checkoutResponse.invoice.hash;
		window.Payment.setPaymentOption(paymentOption, paymentMethod, hash); 
	}

	function addPaymentEvent_FormSubmitted() {
		AnalyticsHelper.TrackPaymentFormEvents('payment-form-submitted', 'payment-form-submitted');
	}
	function addPaymentEvent_TsAndCsChecked() {
		AnalyticsHelper.TrackPaymentFormEvents('ts-and-cs-checked', 'ts-and-cs-checked');
	}
	function addPaymentEvent_MethodChanged(event) {
		AnalyticsHelper.TrackPaymentFormEvents('payment-method-changed', event.detail.paymentMethodName);
	}

	let content;

	if (isLoaded && (!checkoutResponse || checkoutResponse.length === 0)) {
		content = <p>{t("UNABLE_TO_LOAD_CHECKOUT")}</p>
	} else if (isLoaded) {
		content = (
			<Checkout
				checkoutResponse={checkoutResponse}
				selectedPaymentOption={selectedPaymentOption}
				setErrorMessage={setError}
				setSuccessMessage={setSuccess}
				startCheckout={startCheckout}
				paymentOptionClickHandler={paymentOptionClickHandler}
				isSdkLoaded={isSdkLoaded}
			/>
		);
	} else {
		content = (
			<>
				<div className="mb-5"><Skeleton height={45} /></div>
				<div className="mb-5"><Skeleton height={45} count={4} /></div>
				<div className="mb-2"><Skeleton height={45} /></div>
				<div className="mb-3"><Skeleton height={20} /></div>
			</>
		);
	}

	return (
		<>
			{
				(isPaymentBeingProcessed || isPaymentCompleted) && <FullScreenLoading />
			}
			<CheckoutMobileHeading summaryData={props.summaryData} />
			<div className="card colored-bottom checkout-card">
				<div className="card-body">
					{
						errorMessage && <ErrorMessage message={errorMessage} marginClass="mb-0 mb-md-4" />
					}
					{
						successMessage && <ErrorMessage message={successMessage} success={true} marginClass="mb-0 mb-md-4" />
					}
					<div className="checkout-wrap">
						{content}
						<div className="payment-details-wrap">
							<div id="vp-payment-gateway"></div>
							<div className="transaction-secured">{t("PAYMENT_DISCLAIMER")}</div>
						</div>
					</div>
				</div>
			</div>
			<Navigation completedStep="payment" />
		</>
	)
}

export default Payment;